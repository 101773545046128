import { FunctionalComponent, h } from "preact";
import s from "./typography.module.css";

interface TypographyProps {
  dark?: boolean;
  className?: string;
}

export const Typography: FunctionalComponent<TypographyProps> = ({
  children,
  dark,
  className,
}) => {
  const classes = `${
    dark ? `${s.typography} ${s.typographyDark}` : s.typography
  }${className ? ` ${className}` : ""}`;
  return <p className={classes}>{children}</p>;
};

interface TypographyFooterProps {
  dark?: boolean;
}

export const TypographyFooter: FunctionalComponent<TypographyFooterProps> = ({
  children,
  dark,
}) => {
  const className = dark
    ? `${s.typographyFooter} ${s.typographyFooterDark}`
    : s.typographyFooter;
  return <p className={className}>{children}</p>;
};

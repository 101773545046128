import Big from "big.js";

type Currency = {
  code: string;
  fraction: number;
  symbol: string;
  display: string;
  destinationAmountFraction: number;
};

const fx = {
  /**
   * from => to
   */
  convertToCurrency: ({
    fromAmount,
    exchangeRate,
    toCurrency,
  }: {
    fromAmount: number;
    exchangeRate: string;
    toCurrency: Currency;
  }): string => {
    if (fromAmount === 0) return "0";

    if (fromAmount < 0) {
      throw new Error("From currency must be a positive number");
    }

    const computedToValue = Big(fromAmount)
      .times(exchangeRate)
      .round(toCurrency.destinationAmountFraction, Big.roundUp)
      .toString();

    return computedToValue;
  },

  /**
   * to <= from
   */
  convertFromCurrency: ({
    fromAmount,
    exchangeRate,
    toCurrency,
  }: {
    fromAmount: number;
    exchangeRate: string;
    toCurrency: Currency;
  }): string => {
    if (fromAmount === 0) return "0";

    if (fromAmount < 0) {
      throw new Error("From currency must be a positive number");
    }

    const computedToValue = Big(fromAmount)
      .div(exchangeRate)
      .round(toCurrency.fraction, Big.roundDown)
      .toString();

    return computedToValue;
  },
};

export default fx;

import { FunctionalComponent, h } from "preact";
import { ArrowsIcon } from "./arrowsIcon";
import s from "./fxRateCopy.module.css";

export const FxRateCopy: FunctionalComponent = ({ children }) => {
  return (
    <div className={s.outer}>
      <div className={s.inner}>
        {children && <ArrowsIcon className={s.arrows} />}
        {children}
      </div>
    </div>
  );
};

import { h, VNode, Fragment } from "preact";
import { Typography, TypographyFooter } from "../typography/typography";
import { NalaLogo } from "../nalaLogo/nalaLogo";
import s from "./compareRatesTab.module.css";
import { PillSmall } from "../pillSmall/pillSmall";
import { useEffect, useMemo, useReducer } from "preact/hooks";
import { calculate, reducer } from "../../reducer/reducer";
import {
  countryCodeToCurrencyLongName,
  currenciesConfig,
} from "../../i18n/currenciesConfig";
import { DropdownButton } from "../dropdown/button/dropdownButton";
import uniqby from "lodash.uniqby";
import { RateLabel } from "../label/label";
import Big from "big.js";
import { CTA } from "../cta/cta";
import { formatDateToLocalTimeWithZone } from "./utils";

type CompareRatesTabProps = {
  rates: CalculatorRate[];
  downloadNalaLink: string;
};

const getIntialState = (
  fromCurrency: string,
  toCurrency: string
): CalculatorState =>
  calculate({
    fromCurrency: {
      currencyCode: fromCurrency,
      currencyName: countryCodeToCurrencyLongName(fromCurrency),
      countryCode: currenciesConfig[fromCurrency].countryCodes[0],
    },
    fromAmount: "0",
    toCurrency: {
      currencyCode: toCurrency,
      currencyName: countryCodeToCurrencyLongName(toCurrency),
      countryCode: currenciesConfig[toCurrency].countryCodes[0],
    },
    toAmount: "0",
    direction: "NORMAL",
  });

export const CompareRatesTab = ({
  rates: allRates,
  downloadNalaLink,
}: CompareRatesTabProps): VNode => {
  const [state, dispatch] = useReducer(reducer, getIntialState("GBP", "KES"));
  const { fromCurrency, toCurrency, rates } = state;

  useEffect(() => {
    if (allRates && allRates.length > 0) {
      dispatch({ type: "addPairs", payload: allRates });
      dispatch({ type: "fromAmountChange", payload: "1000" });
    }
  }, [allRates]);

  const sourceCurrencies = useMemo(
    () =>
      rates &&
      uniqby(
        rates
          .map((rate) => rate.sourceCurrency)
          .sort((a, b) => (a.currencyCode > b.currencyCode ? 1 : -1)),
        "countryCode"
      ),
    [rates]
  );

  const destinationCurrencies = useMemo(
    () =>
      rates &&
      uniqby(
        rates
          .map((rate) => rate.destinationCurrency)
          .sort((a, b) => (a.currencyCode > b.currencyCode ? 1 : -1)),
        "countryCode"
      ),
    [rates]
  );

  const compareRates = useMemo(() => {
    if (!rates) return [];
    return rates
      .filter(
        (rate) =>
          rate.sourceCurrency.currencyCode === fromCurrency.currencyCode &&
          rate.destinationCurrency.currencyCode === toCurrency.currencyCode
      )
      .reduce<CalculatorRate[]>((acc, rate) => {
        const duplicateIndex = acc.findIndex(
          (existingRate) =>
            existingRate.rate === rate.rate &&
            existingRate.providerName === rate.providerName
        );

        if (duplicateIndex === -1) {
          acc.push(rate);
        }
        return acc;
      }, [])
      .sort((a, b) => parseFloat(b.rate) - parseFloat(a.rate)); // Changed here from a.rate - b.rate to b.rate - a.rate
  }, [rates, fromCurrency, toCurrency]);

  const fxRateHumanFriendly = (rate: CalculatorRate) => {
    const multipliedRate = Big(rate.rate).times(1000).round(2, Big.roundUp);

    const formattedRate = Number(multipliedRate).toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    return `${formattedRate} ${rate.destinationCurrency.currencyCode}`;
  };

  const currentDate = new Date();
  const formattedDate = currentDate.toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });

  return (
    <div className={s.tabContainer}>
      <div className={s.inputRow}>
        <div className={s.inputColumn}>
          <RateLabel>When sending</RateLabel>
          <DropdownButton
            initialValue={fromCurrency}
            currencies={sourceCurrencies}
            onChange={(el) => {
              dispatch({ type: "fromCurrencyChange", payload: el });
            }}
          />
        </div>
        <div className={s.arrowContainer}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="26"
            height="16"
            viewBox="0 0 26 16"
            fill="none"
          >
            <path
              d="M1.97322 8L24.0268 8M24.0268 8L18.0893 2.0625M24.0268 8L18.0893 13.9375"
              stroke="#00a2dc"
              stroke-width="3"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>

        <div className={s.inputColumn}>
          <RateLabel>Choose currency</RateLabel>
          <DropdownButton
            initialValue={toCurrency}
            currencies={destinationCurrencies}
            onChange={(el) => {
              dispatch({ type: "toCurrencyChange", payload: el });
            }}
            rightAligned
          />
        </div>
      </div>
      {compareRates.length > 0 ? (
        <div>
          <h3 className={s.rateHeading}>Today's rates {formattedDate}</h3>
          <div className={s.ratesTable}>
            <Typography dark>Sending with</Typography>
            <Typography className={s.recipientHeading} dark>
              Recipient gets
            </Typography>
            {compareRates.map((rate, i) => (
              <Fragment key={rate.providerName}>
                <div>
                  {rate.providerName === "nala" ? (
                    <NalaLogo />
                  ) : (
                    <h4 className={s.providerHeading}>{rate.providerName}</h4>
                  )}
                  <p className={s.rateTime}>
                    Rates as of: {formatDateToLocalTimeWithZone(rate.createdAt)}
                  </p>
                </div>
                <PillSmall hasAccentColor={i === 0}>
                  <h4 className={s.providerHeading}>
                    {fxRateHumanFriendly(rate)}
                  </h4>
                </PillSmall>
              </Fragment>
            ))}
          </div>
        </div>
      ) : (
        <div className={s.loadingContainer}>
          <p className={s.transparencyText}>Loading rates...</p>
        </div>
      )}
      <div style={{ marginBottom: "4em" }}>
        <CTA href={downloadNalaLink}>Download NALA ❤️</CTA>
      </div>
      <TypographyFooter dark>
        We believe in increasing transparency in finance. We charge as little as
        possible. No hidden fees. No surprises.
      </TypographyFooter>
      <TypographyFooter dark>
        <a href="https://help.nala.money/en/articles/6331677-how-does-nala-compare-rates">
          How do we collect this data?
        </a>
      </TypographyFooter>
    </div>
  );
};

import { FunctionalComponent, h } from "preact";
import s from "./cta.module.css";

export const CTA: FunctionalComponent<{ href?: string }> = ({
  children,
  href = "#",
}) => {
  return (
    <a className={s.cta} href={href}>
      {children}
    </a>
  );
};

import { format } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import enIN from "date-fns/locale/en-IN";

const getLocalTimeZoneAbbreviation = (date: Date): string => {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone; // Get the user's local timezone
  const zonedDate = utcToZonedTime(date, timeZone);
  return format(zonedDate, "zzz", { locale: enIN });
};

export const formatDateToLocalTimeWithZone = (inputDate: Date): string => {
  const timeZoneAbbreviation = getLocalTimeZoneAbbreviation(inputDate);
  return `${format(inputDate, "HH:mm")} ${timeZoneAbbreviation}`;
};

import { FunctionalComponent, h } from "preact";

import S from "./pillSmall.module.css";

type PillSmallProps = {
  hasAccentColor?: boolean;
};

export const PillSmall: FunctionalComponent<PillSmallProps> = ({
  children,
  hasAccentColor,
}) => (
  <div className={`${S.pillSmall} ${hasAccentColor ? S.pillSmallAccent : ""}`}>
    {children}
  </div>
);

import { createRef, h, VNode } from "preact";
import { useState } from "preact/hooks";
import { useClickOutside } from "../../../hooks";
import { Flag } from "../../flag/flag";
import { ArrowIcon } from "../arrowIcon";
import s from "./dropdownButton.module.css";

export const DropdownButton = ({
  currencies,
  onChange,
  initialValue,
  partOfCurrencyInput,
  rightAligned,
}: {
  currencies?: CalculatorCurrency[];
  onChange: (currency: CalculatorCurrency) => void;
  initialValue: CalculatorCurrency;
  partOfCurrencyInput?: boolean;
  rightAligned?: boolean;
}): VNode => {
  const [isVisible, setIsVisible] = useState(false);
  const [selectedValue, setSelectedValue] = useState(initialValue);

  const ref = createRef();
  useClickOutside(ref, () => {
    setIsVisible(false);
  });

  const handleSelect = (selectedCurrency: CalculatorCurrency) => {
    setSelectedValue(selectedCurrency);
    setIsVisible(false);
    onChange(selectedCurrency);
  };

  const sendingCompareRate = !partOfCurrencyInput && !rightAligned;

  return (
    <div className={s.container} ref={ref}>
      <button
        className={`${s.button} ${
          partOfCurrencyInput === true ? s.currencyInputButton : ""
        }
       `}
        onClick={() => setIsVisible(!isVisible)}
      >
        {selectedValue && <Flag code={selectedValue.countryCode} />}
        <span className={s.value}>
          {sendingCompareRate && 1000} {selectedValue.currencyCode}
        </span>
        <ArrowIcon />
      </button>
      {isVisible && (
        <ul
          className={`${s.list} ${
            partOfCurrencyInput === true ? s.currencyInputList : ""
          } ${rightAligned === true ? s.rightAligned : ""}`}
        >
          {currencies &&
            currencies.map((currency) => {
              return (
                <li
                  className={
                    currency.countryCode === selectedValue.countryCode
                      ? `${s.listElement}${` `}${s.listElementSelected}`
                      : s.listElement
                  }
                  key={currency}
                  onClick={() => {
                    handleSelect(currency);
                  }}
                >
                  <div className={s.listFlagCurrency}>
                    <Flag code={currency.countryCode} />
                    <span className={s.listElementText}>
                      {sendingCompareRate && 1000} {currency.currencyCode}
                    </span>
                  </div>
                  <span className={s.listElementText}>
                    {currency.currencyName}
                  </span>
                </li>
              );
            })}
        </ul>
      )}
    </div>
  );
};

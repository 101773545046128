import { currenciesConfig } from "../i18n/currenciesConfig";
import fx from "./fx";

export const calculate = (props: CalculationProps): CalculatorState => {
  const { fromCurrency, fromAmount, toCurrency, toAmount, direction, rates } =
    props;

  if (!rates) {
    return props;
  }

  const rate = rates.find(
    (pair) =>
      pair.sourceCurrency.countryCode === fromCurrency.countryCode &&
      pair.destinationCurrency.countryCode === toCurrency.countryCode
  );

  if (!rate?.rate) {
    return props;
  }

  if (direction === "NORMAL") {
    const currencyConfig = currenciesConfig[toCurrency.currencyCode];
    return {
      ...props,
      toAmount: fx.convertToCurrency({
        fromAmount: Number(fromAmount),
        exchangeRate: rate.rate,
        toCurrency: currencyConfig,
      }),
    };
  }
  const currencyConfig = currenciesConfig[toCurrency.currencyCode];
  return {
    ...props,
    fromAmount: fx.convertFromCurrency({
      fromAmount: Number(toAmount),
      exchangeRate: rate.rate,
      toCurrency: currencyConfig,
    }),
  };
};

export const reducer = (
  state: CalculatorState,
  action: Action
): CalculatorState => {
  switch (action.type) {
    case "fromCurrencyChange":
      return calculate({
        ...state,
        fromCurrency: action.payload,
        direction: "NORMAL",
      });
    case "toCurrencyChange":
      return calculate({
        ...state,
        toCurrency: action.payload,
        direction: "NORMAL",
      });
    case "fromAmountChange":
      return calculate({
        ...state,
        fromAmount: action.payload,
        direction: "NORMAL",
      });
    case "toAmountChange":
      return calculate({
        ...state,
        toAmount: action.payload,
        direction: "REVERSED",
      });
    case "addPairs":
      return calculate({
        ...state,
        rates: action.payload,
        direction: "NORMAL",
      });
    default:
      return state;
  }
};

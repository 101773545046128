import { FunctionalComponent, h } from "preact";
import s from "./tabToggle.module.css";
import { useState } from "preact/hooks";

export enum CalculatorTabs {
  "RATE_CALCULATOR",
  "COMPARE_RATES",
}

type TabToggleProps = {
  onTabChange: (tab: CalculatorTabs) => void;
};

export const TabToggle: FunctionalComponent<TabToggleProps> = ({
  onTabChange,
}) => {
  const [selectedTab, setSelectedTab] = useState<CalculatorTabs>(
    CalculatorTabs.RATE_CALCULATOR
  );

  const handleChange = (event) => {
    const tabValue = event.target.value as keyof typeof CalculatorTabs;
    const newSelectedTab = CalculatorTabs[tabValue];
    setSelectedTab(newSelectedTab);
    onTabChange(newSelectedTab);
  };

  return (
    <div className={s.container}>
      <input
        type="radio"
        id="radio-1"
        name="tabs"
        value={CalculatorTabs[CalculatorTabs.RATE_CALCULATOR]}
        checked={selectedTab === CalculatorTabs.RATE_CALCULATOR}
        onChange={handleChange}
      />
      <label className={s.tab} htmlFor="radio-1">
        Rates calculator
      </label>

      <input
        type="radio"
        id="radio-2"
        name="tabs"
        value={CalculatorTabs[CalculatorTabs.COMPARE_RATES]}
        checked={selectedTab === CalculatorTabs.COMPARE_RATES}
        onChange={handleChange}
      />
      <label className={s.tab} htmlFor="radio-2">
        Compare rates
      </label>

      <span className={s.glider} />
    </div>
  );
};

import preact from "preact";
import { useEffect } from "preact/hooks";

export const useClickOutside = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ref: preact.RefObject<any>,
  callback: () => void
): void => {
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    };
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, callback]);
};

export const formatNumberString = (numString: string): string => {
  // Remove any non-numeric characters
  numString = numString.replace(/[^0-9.-]/g, "");

  // Check if the input is a valid number string
  if (isNaN(Number(numString)) || Number(numString) < 0) {
    return "Invalid amount";
  }

  if (numString === "") {
    return "";
  }

  // Split the number string into integer and decimal parts
  const parts = numString.split(".");
  let integerPart = parts[0];
  let decimalPart = parts.length > 1 ? `.${parts[1]}` : "";

  // Round decimal part to 2 fixed decimal places
  if (decimalPart !== "" && decimalPart.length > 3) {
    const tempStringNumber = Number(`0${decimalPart}`);
    decimalPart = `.${tempStringNumber.toFixed(2).split(".")[1]}`;
  }

  // Handle leading zeros
  if (integerPart === "0" || integerPart === "") {
    integerPart = "0";
  } else {
    integerPart = String(parseInt(integerPart, 10));
  }

  // Add commas every three digits in the integer part
  let formattedIntegerPart = "";
  for (let i = integerPart.length - 1, j = 1; i >= 0; i--, j++) {
    if (j % 3 === 0 && i !== 0) {
      formattedIntegerPart = `,${integerPart.charAt(i)}${formattedIntegerPart}`;
    } else {
      formattedIntegerPart = integerPart.charAt(i) + formattedIntegerPart;
    }
  }

  // Combine the formatted integer and decimal parts and add back the negative sign
  const formattedNumString = formattedIntegerPart + decimalPart;

  return formattedNumString;
};

export const unformatNumberString = (formattedNumString: string): string => {
  // Remove commas from the string
  const unformattedNumString = formattedNumString.replace(/,/g, "");

  return unformattedNumString;
};

import { h, VNode } from "preact";
import { useEffect, useState } from "preact/hooks";
import s from "./calculator.module.css";
import { CalculatorTabs, TabToggle } from "../tabToggle/tabToggle";
import { RateCalculatorTab } from "../tabs/rateCalculatorTab";
import { CompareRatesTab } from "../tabs/compareRatesTab";
import { fetchRates } from "../../api/fetchRates";

interface CalculatorProps {
  fetchRatesApi: string;
  downloadNalaLink: string;
  faqLink: string;
  fromCurrencyCode: string;
  toCurrencyCode: string;
}

export default function Calculator(props: CalculatorProps): VNode {
  const [currentTab, setCurrentTab] = useState<CalculatorTabs>(
    CalculatorTabs.RATE_CALCULATOR
  );
  const [rates, setRates] = useState<CalculatorRate[]>([]); // Assuming RateType is the type for your rates

  useEffect(() => {
    const fetchRatesData = async () => {
      const fetchedRates = await fetchRates(props.fetchRatesApi);
      setRates(fetchedRates);
    };

    fetchRatesData();
  }, [props.fetchRatesApi]);

  const handleTabChange = (tab: CalculatorTabs) => {
    setCurrentTab(tab);
  };

  return (
    <div className="nala-calculator">
      <div
        className={`${s.container} ${
          currentTab === CalculatorTabs.RATE_CALCULATOR
            ? s.rateCalculator
            : s.compareRates
        } `}
      >
        <TabToggle onTabChange={handleTabChange} />

        {currentTab === CalculatorTabs.RATE_CALCULATOR ? (
          <RateCalculatorTab
            downloadNalaLink={props.downloadNalaLink}
            faqLink={props.faqLink}
            fromCurrencyCode={props.fromCurrencyCode}
            toCurrencyCode={props.toCurrencyCode}
            rates={rates}
          />
        ) : (
          <CompareRatesTab
            rates={rates}
            downloadNalaLink={props.downloadNalaLink}
          />
        )}
      </div>
    </div>
  );
}

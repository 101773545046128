import { h, VNode } from "preact";

export const ArrowIcon = (): VNode => (
  <svg
    width="21"
    height="13"
    viewBox="0 0 21 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.1064 7.70632L3.3214 0.880822C2.58625 0.141289 1.39296 0.141289 0.657808 0.880822C-0.0773423 1.62036 -0.0773423 2.82076 0.657808 3.56029L8.85101 11.8023C9.54532 12.5008 10.6694 12.5008 11.3619 11.8023L19.5551 3.56029C20.2902 2.82076 20.2902 1.62036 19.5551 0.880822C18.8199 0.141289 17.6266 0.141289 16.8915 0.880822L10.1064 7.70632Z"
      fill="#00A2DC"
    />
  </svg>
);

import {
  countryCodeToCurrencyLongName,
  currenciesConfig,
} from "../i18n/currenciesConfig";

export const mapResponseRatesToCalculatorRates = (
  rates: RatesFromAPI[],
  showOnlyNalaRates?: boolean
): CalculatorRate[] => {
  const filteredByProviders = showOnlyNalaRates
    ? rates.filter(({ provider_name }) => provider_name === "nala")
    : rates;

  const withValidConfig = filteredByProviders.filter(
    ({ source_currency, destination_currency }) =>
      currenciesConfig[source_currency] &&
      currenciesConfig[destination_currency]
  );

  const calculatorRates = withValidConfig.reduce((acc, rate) => {
    const sourceCurrencyCountries =
      currenciesConfig[rate.source_currency].countryCodes;

    const destinationCurrencyCountries =
      currenciesConfig[rate.destination_currency].countryCodes;

    sourceCurrencyCountries.forEach((sourceCountryCode) => {
      destinationCurrencyCountries.forEach((destinationCountryCode) => {
        acc.push({
          sourceCurrency: {
            currencyCode: rate.source_currency,
            currencyName: countryCodeToCurrencyLongName(sourceCountryCode),
            countryCode: sourceCountryCode,
          },
          destinationCurrency: {
            currencyCode: rate.destination_currency,
            currencyName: countryCodeToCurrencyLongName(destinationCountryCode),
            countryCode: destinationCountryCode,
          },
          rate: rate.rate,
          // Renaming lemonade to LemFi as they've changed their name
          providerName:
            rate.provider_name === "lemonade" ? "LemFi" : rate.provider_name,
          createdAt: new Date(rate.created_at),
        });
      });
    });

    return acc;
  }, [] as CalculatorRate[]);

  return calculatorRates;
};

export const fetchRates = async (
  api: string,
  showOnlyNalaRates?: boolean
): Promise<CalculatorRate[]> => {
  const rates = await (await fetch(api)).json();
  return mapResponseRatesToCalculatorRates(rates.data, showOnlyNalaRates);
};

import { h, VNode } from "preact";
import s from "./rateCalculatorTab.module.css";

import { Label } from "../label/label";
import {
  countryCodeToCurrencyLongName,
  currenciesConfig,
} from "../../i18n/currenciesConfig";
import { CTA } from "../cta/cta";
import { DropdownButton } from "../dropdown/button/dropdownButton";
import { FxRateCopy } from "../fxRateCopy/fxRateCopy";
import { Input } from "../input/input";
import { TypographyFooter } from "../typography/typography";
import { useEffect, useMemo, useReducer } from "preact/hooks";
import { calculate, reducer } from "../../reducer/reducer";
import uniqby from "lodash.uniqby";
import Big from "big.js";

interface RateCalculatorTabProps {
  faqLink: string;
  downloadNalaLink: string; // Added prop
  fromCurrencyCode: string;
  toCurrencyCode: string;
  rates: CalculatorRate[];
}

const getIntialState = (
  fromCurrency: string,
  toCurrency: string
): CalculatorState =>
  calculate({
    fromCurrency: {
      currencyCode: fromCurrency,
      currencyName: countryCodeToCurrencyLongName(fromCurrency),
      countryCode: currenciesConfig[fromCurrency].countryCodes[0],
    },
    fromAmount: "0",
    toCurrency: {
      currencyCode: toCurrency,
      currencyName: countryCodeToCurrencyLongName(toCurrency),
      countryCode: currenciesConfig[toCurrency].countryCodes[0],
    },
    toAmount: "0",
    direction: "NORMAL",
  });

export const RateCalculatorTab = ({
  fromCurrencyCode,
  toCurrencyCode,
  rates: allRates,
  downloadNalaLink,
}: RateCalculatorTabProps): VNode => {
  const [state, dispatch] = useReducer(
    reducer,
    getIntialState(fromCurrencyCode, toCurrencyCode)
  );

  const { fromAmount, toAmount, fromCurrency, toCurrency, rates } = state;

  const nalaRates = useMemo(() => {
    return allRates.filter((rate) => rate.providerName === "nala");
  }, [allRates]);

  useEffect(() => {
    if (nalaRates.length > 0) {
      dispatch({ type: "addPairs", payload: nalaRates });
      dispatch({ type: "fromAmountChange", payload: "1000" });
    }
  }, [nalaRates]);

  const sourceCurrencies = useMemo(
    () =>
      rates &&
      uniqby(
        rates
          .map((rate) => rate.sourceCurrency)
          .sort((a, b) => (a.currencyCode > b.currencyCode ? 1 : -1)),
        "countryCode"
      ),
    [rates]
  );

  const destinationCurrencies = useMemo(
    () =>
      rates &&
      uniqby(
        rates
          .map((rate) => rate.destinationCurrency)
          .sort((a, b) => (a.currencyCode > b.currencyCode ? 1 : -1)),
        "countryCode"
      ),
    [rates]
  );

  const rate = useMemo(() => {
    if (rates) {
      return rates.find(
        (rate) =>
          rate.sourceCurrency.countryCode === fromCurrency.countryCode &&
          rate.destinationCurrency.countryCode === toCurrency.countryCode
      );
    }
  }, [fromCurrency, rates, toCurrency]);

  const fxRateHumanFriendlyText = useMemo(
    () =>
      rate
        ? `1 ${fromCurrency.currencyCode} ≈ ${Big(rate.rate).round(
            2,
            Big.roundUp
          )} ${toCurrency.currencyCode}`
        : "",
    [fromCurrency, toCurrency, rate]
  );

  return (
    <div className={s.tabContainer}>
      <Label>You send</Label>
      <div style={{ marginBottom: "3em" }}>
        <div className={s.dropdownInputContainer}>
          <DropdownButton
            initialValue={fromCurrency}
            currencies={sourceCurrencies}
            onChange={(el) => {
              dispatch({ type: "fromCurrencyChange", payload: el });
            }}
            partOfCurrencyInput
          />
          <Input
            value={fromAmount}
            allowedPrecision={
              currenciesConfig[fromCurrency.currencyCode].fraction
            }
            onChange={(payload) =>
              dispatch({
                type: "fromAmountChange",
                payload,
              })
            }
          />
        </div>
      </div>

      <div style={{ marginBottom: "2.7em" }}>
        <FxRateCopy>{fxRateHumanFriendlyText}</FxRateCopy>
      </div>

      <Label>Individual receives</Label>
      <div style={{ marginBottom: "4em" }}>
        <div className={s.dropdownInputContainer}>
          <DropdownButton
            initialValue={toCurrency}
            currencies={destinationCurrencies}
            onChange={(el) =>
              dispatch({ type: "toCurrencyChange", payload: el })
            }
            partOfCurrencyInput
          />
          <Input
            value={toAmount}
            allowedPrecision={
              currenciesConfig[toCurrency.currencyCode]
                .destinationAmountFraction
            }
            onChange={(payload) =>
              dispatch({
                type: "toAmountChange",
                payload,
              })
            }
          />
        </div>
      </div>

      <div style={{ marginBottom: "4em" }}>
        <CTA href={downloadNalaLink}>Download NALA ❤️</CTA>
      </div>

      <TypographyFooter>
        Looking for our business rates?{" "}
        <a href="mailto:sales@nala.com">Contact sales!</a> We charge fees for
        certain transactions to cover our cost. See our{" "}
        <a
          href={
            "https://help.nala.money/en/articles/7193797-how-much-does-it-cost-to-use-nala"
          }
          target="_blank"
          rel="noreferrer"
        >
          FAQ
        </a>
        .
      </TypographyFooter>
    </div>
  );
};

import debounce from "lodash.debounce";
import { FunctionalComponent, h } from "preact";
import { useEffect, useRef } from "preact/hooks";
import s from "./input.module.css";
import { formatNumberString, unformatNumberString } from "./utils";

export const Input: FunctionalComponent<{
  onChange: (payload: string) => void;
  value: string;
  allowedPrecision?: number;
}> = ({ onChange, value, allowedPrecision = 2 }) => {
  const isActive = useRef(false);
  const ref = useRef<HTMLInputElement>();

  useEffect(() => {
    if (!isActive.current) {
      ref.current.value = formatNumberString(value);
    }
  }, [value]);

  const formatInput = (stringValue?: string) => {
    const newFormatted = formatNumberString(
      unformatNumberString(stringValue || ref.current.value)
    );
    ref.current.value = formatNumberString(newFormatted);
  };

  const handleFocus = () => {
    isActive.current = true;
  };

  const handleBlur = () => {
    isActive.current = false;
    formatInput();
  };

  const handleChange = (e) => {
    onChange(unformatNumberString(e.target.value));
  };

  const handleKeyPress = (e) => {
    const isNumber = /[0-9]/.test(e.key);
    const inputValue = ref.current.value;
    const cursorPosition = ref.current.selectionStart || 0;

    const isComma = e.key === ",";
    const isDoubleComma = inputValue[cursorPosition - 1] === ",";
    const isLastChar = cursorPosition === inputValue.length;
    const isValidComma = isComma && !isDoubleComma && !isLastChar;

    const isDot = e.key === ".";
    const hasDotAlready = inputValue.includes(".");
    const isValidDot = isDot && !hasDotAlready && allowedPrecision > 0;
    const dotPosition = inputValue.indexOf(".");

    const isTooBigPrecisionNumber =
      hasDotAlready && cursorPosition - dotPosition > allowedPrecision;

    const isAllowed =
      (isNumber || isValidDot || isValidComma) && !isTooBigPrecisionNumber;

    if (!isAllowed) {
      e.preventDefault();
    }
  };

  const handlePaste = (e) => {
    e.preventDefault();
    formatInput(e.clipboardData.getData("text"));
  };

  const debounceHandleChange = debounce(handleChange, 100);

  return (
    <input
      className={s.input}
      ref={ref}
      type="text"
      inputMode="decimal"
      onFocus={handleFocus}
      onBlur={handleBlur}
      onInput={debounceHandleChange}
      maxLength={12}
      onKeyPress={handleKeyPress}
      onPaste={handlePaste}
    />
  );
};

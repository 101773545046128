import { FunctionalComponent, h, VNode } from "preact";
import s from "./label.module.css";

export const Label: FunctionalComponent = ({ children }): VNode => {
  return <label className={s.label}>{children}</label>;
};

export const RateLabel: FunctionalComponent = ({ children }): VNode => {
  return <label className={s.rateLabel}>{children}</label>;
};
